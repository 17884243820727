import { ref } from '@nuxtjs/composition-api'
import { useEventListener } from './useEventListener'

export const useMedia = (query: string) => {
  if (process.client) {
    const mediaQuery = window.matchMedia(query)
    const matches = ref(mediaQuery.matches)

    useEventListener(mediaQuery, 'change', (e: MediaQueryListEvent) => {
      matches.value = e.matches
    })

    return matches
  }
}
