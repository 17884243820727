import mitt from 'mitt'
// import { useContext } from '@nuxtjs/composition-api'
import { Dictionary } from 'ramda';

const eventBus = mitt()
const registeredHandlers: Dictionary<Set<string>> = {}

export declare type Handler<T = unknown> = (event: T) => void;

export const useEvents = () => {
  // const { isDev } = useContext()

  const emit = <T>(key: string, data?: T) => {
    eventBus.emit(key, data)
  }

  const on = <T>(key: string, handler: Handler<T>, handlerKey?: string) => {
    if (handlerKey) {
      let handlerKeys = registeredHandlers[key]
      if (!handlerKeys) {
        registeredHandlers[key] = handlerKeys = new Set<string>()
      }
      if (handlerKeys.has(handlerKey))
        return

      handlerKeys.add(handlerKey)
    }

    eventBus.on(key, data => handler(data as any))
  }

  return {
    emit,
    on
  }
}