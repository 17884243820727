import { computed } from '@nuxtjs/composition-api'
import { useMedia } from './useMedia'

// align with tailwindcss breakpoint
export enum Breakpoint {
  XSM = '425PX',
  SM = '640px',
  MD = '768px',
  XLG = '980px',
  LG = '1024px',
  XL = '1280px',
  XXL = '1536px',
}

export function useScreenSize() {
  const isXsm = useMedia(`(min-width: ${Breakpoint.XSM})`)
  const isSm = useMedia(`(min-width: ${Breakpoint.SM})`)
  const isMd = useMedia(`(min-width: ${Breakpoint.MD})`)
  const isXlg = useMedia(`(min-width: ${Breakpoint.XLG})`)
  const islg = useMedia(`(min-width: ${Breakpoint.LG})`)
  const isXL = useMedia(`(min-width: ${Breakpoint.XL})`)
  const is2xl = useMedia(`(min-width: ${Breakpoint.XXL})`)

  return computed(() => {
    if (is2xl?.value) {
      return '2xl'
    }
    if (isXL?.value) {
      return 'xl'
    }
    if (islg?.value) {
      return 'lg'
    }
    if (isXlg?.value) {
      return 'xlg'
    }
    if (isMd?.value) {
      return 'md'
    }
    if (isSm?.value) {
      return 'sm'
    }
    if (isXsm?.value) {
      return 'xsm'
    }
    return 'xs'
  })
}
