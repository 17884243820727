import { computed } from '@nuxtjs/composition-api'
import { useScreenSize } from './useScreenSize'

export function useBreakpoint() {
  const screenSize = useScreenSize()

  const isMobile = computed(
    () =>
      screenSize.value === 'xs' ||
      screenSize.value === 'xsm' ||
      screenSize.value === 'sm'
  )
  const isTablet = computed(
    () => screenSize.value === 'md' || screenSize.value === 'xlg'
  )
  const isDesktop = computed(
    () =>
      screenSize.value === 'lg' ||
      screenSize.value === 'xl' ||
      screenSize.value === '2xl'
  )
  const isTabletAndDesktop = computed(
    () => screenSize.value !== 'xs' && screenSize.value !== 'xsm' && screenSize.value !== 'sm'
  )
  const isMobileAndTablet = computed(
    () =>
      screenSize.value === 'xs' ||
      screenSize.value === 'xsm' ||
      screenSize.value === 'sm' ||
      screenSize.value === 'xlg' ||
      screenSize.value === 'md'
  )

  return {
    isMobile,
    isTablet,
    isDesktop,
    isTabletAndDesktop,
    isMobileAndTablet,
  }
}
